<template>
  <layout
    style="background: #f2f2f2;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div class="expande-horizontal">
      <v-flex xs12 md3>
        <Filtros class="pl-3" />
        <v-flex
          class="pa-3"
          v-if="getPersonalizedLoading === 'listando-insumos'"
          xs12
        >
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="list-item-avatar, divider"
          ></v-skeleton-loader>
        </v-flex>
        <v-flex class="pa-3" v-else xs12>
          <v-list class="pa-0 ma-0" dense style="height: 55vh; overflow: auto;">
            <template v-for="(insumo, index) in get_insumos.docs">
              <v-list-item
                @click="abririnsumo(insumo, index)"
                style="border-radius: 6px; background: #f2f2f2; margin-bottom: 6px;"
                :key="insumo._id"
              >
                <v-list-item-content>
                  <v-list-item-title class="fonte fonte-bold fonte-subtitulo">
                    {{ insumo.nome }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte fonte-subtitulo">
                    {{ insumo.descricao }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    :color="$theme.primary"
                    class="animate__animated animate__fadeIn"
                    v-if="get_insumo._id === insumo._id"
                    icon
                  >
                    <v-icon> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-flex>
        <v-flex v-if="get_insumos.docs.length === 0" xs12>
          <div
            class="expande-horizontal pa-6 column centraliza"
            style="height: 36vh;"
          >
            <img style="width: 80%" src="img/empty.gif" alt="empty" />
            <span
              class="px-3 fonte-italica fonte-subtitulo grey--text text-center"
            >
              Você não tem insumos cadastrados ainda, clique no botão abaixo e
              cadastre o primeiro!
            </span>
            <v-btn
              small
              rounded
              dark
              class=" mt-6 fonte-subtitulo fonte-italica"
              :color="$theme.secondary"
              @click="iniciarCadastro"
            >
              Novo insumo
              <v-icon
                size="12"
                class="ml-1 animate__animated"
                :class="getPersonalizedLoading"
                >mdi-arrow-projectile</v-icon
              >
            </v-btn>
          </div>
        </v-flex>
        <v-flex v-if="get_insumos.docs.length" class="pa-3" xs12>
          <Paginacao />
        </v-flex>
      </v-flex>
      <v-flex xs12 md6>
        <div
          v-if="get_insumo.new || get_insumo._id"
          class="expande-horizontal wrap"
        >
          <v-flex xs12>
            <div class="expande-horizontal column">
              <transition name="slide-fade">
                <span
                  class="fonte-default fonte-medium animate__animated"
                  :class="getPersonalizedLoading"
                >
                  {{ get_insumo.new ? "Novo insumo" : get_insumo.nome }}
                </span>
              </transition>
              <v-divider></v-divider>
              <span class="pb-6 grey--text fonte-subtitulo fonte-italica">
                Informe algumas informações sobre este insumo
              </span>
            </div>
          </v-flex>
          <!-- <v-flex xs12>
            <div class="expande-horizontal">
              <modal-send-archive />
            </div>
          </v-flex> -->
          <v-flex xs12>
            <v-form ref="form">
              <v-flex xs12>
                <div class="expande-horizontal wrap">
                  <v-flex class="pr-3" xs12 md4>
                    <span class="fonte-italica fonte-subtitulo">
                      Nome
                    </span>
                    <v-text-field
                      solo
                      flat
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: Alface"
                      v-model="get_insumo.nome"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md8>
                    <span class="fonte-italica fonte-subtitulo">
                      Descrição
                    </span>
                    <v-text-field
                      solo
                      flat
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: Alfaces frescos"
                      v-model="get_insumo.descricao"
                    ></v-text-field>
                  </v-flex>
                </div>
              </v-flex>
            </v-form>
          </v-flex>
          <v-flex xs12>
            <v-form ref="formEstoque">
              <v-flex xs12>
                <div class="expande-horizontal wrap">
                  <v-flex class="pr-3" xs12 md4>
                    <span class="fonte-italica fonte-subtitulo">
                      Preço de Compra
                    </span>
                    <v-text-field
                      solo
                      flat
                      prefix="R$"
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: Alface"
                      v-mask="['#.##', '##.##', '###.##', '####.##']"
                      v-model="get_insumo.preco_de_compra"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="pr-3" xs12 md8>
                    <span class="fonte-italica fonte-subtitulo">
                      Unidade de Medida
                    </span>
                    <v-select
                      solo
                      flat
                      dense
                      class="fonte-subtitulo fonte-italica"
                      background-color="#f2f2f2"
                      placeholder="ex: UND"
                      :items="['GRAMA', 'UNIDADE', 'ML']"
                      hint="1 KG = 1000 GRAMAS"
                      v-model="get_insumo.unidade_de_medida"
                    ></v-select>
                  </v-flex>
                </div>
              </v-flex>
            </v-form>
          </v-flex>
          <v-flex xs12 class="pb-6">
            <div class="expande-horizontal">
              <v-flex xs12 md3>
                <v-btn
                  @click="criarOuAtualizar"
                  color="green"
                  dark
                  class="fonte-subtitulo text-capitalize"
                >
                  Salvar
                </v-btn>
              </v-flex>
            </div>
          </v-flex>
        </div>
      </v-flex>
      <v-flex xs12 md3>
        <div class="expande-horizontal wrap">
          <v-flex xs12>
            <div class="expande-horizontal column">
              <transition name="slide-fade">
                <span
                  class="fonte-default fonte-medium animate__animated"
                  :class="getPersonalizedLoading"
                >
                  Estoque
                </span>
              </transition>
              <v-divider></v-divider>
              <span class="pb-3 grey--text fonte-subtitulo fonte-italica">
                Informações de estoque
              </span>
              <v-flex xs12>
                <div
                  class="expande-horizontal column fonte-subtitulo mb-2 fonte-italica"
                >
                  <h3>
                    Qtd em estoque: {{ quantidadeEstoque.quantidade }}
                    {{ get_insumo.unidade_de_medida }}
                  </h3>
                  <h3>
                    Valor do estoque:
                    {{ $helper.formataSaldo(quantidadeEstoque.valor_total) }}
                  </h3>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-list three-line class="pa-0 ma-0">
                  <template v-for="estoque in get_estoques.docs">
                    <v-list-item
                      style="border-radius: 5px; background: #f2f2f2;"
                      class="ma-0 mb-2 pa-0"
                      :key="estoque._id"
                    >
                      <v-icon
                        size="18"
                        class="ml-2 mr-2"
                        color="green"
                        v-if="estoque.quantidade > 0"
                      >
                        mdi-arrow-up-circle-outline
                      </v-icon>
                      <v-icon size="18" class="ml-2 mr-2" color="red" v-else>
                        mdi-arrow-down-circle-outline
                      </v-icon>
                      <v-list-item-content
                        class="fonte-subtitulo fonte-italica font-weight-bold"
                      >
                        <v-list-item-title>
                          {{ estoque.quantidade }}
                          {{ estoque.unidade_de_medida }} de
                          {{ estoque.produto.nome }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Preço Base:
                          {{
                            $helper.formataSaldo(
                              estoque.produto.preco_de_compra
                            )
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          Total comprado: R$ {{ estoque | precoDeEntrada }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="fonte-micro"
                          >{{
                            $moment(estoque.created_at).format(
                              "DD/MM/YY [às] HH:mm"
                            )
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-flex>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </div>
    <v-dialog
      fullscreen
      persistent
      v-model="formEstoqueDialog"
      style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
    >
      <div
        class="expande-horizontal centraliza"
        style="height: 100vh; background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);"
      >
        <v-card width="500" class="expande-horizontal wrap">
          <div class="expande-horizontal px-1 pr-3 py-3 pb-0">
            <v-btn dark @click="formEstoqueDialog = false" icon>
              <v-icon color="orange">
                mdi-chevron-left
              </v-icon>
            </v-btn>
            <span
              style="font-size: 17pt; width: 100%;"
              class="fonte-subtitulo font-weight-bold fonte-italica"
            >
              Adicionar Estoque Para {{ get_insumo.nome }}
            </span>
            <v-btn icon @click="formEstoqueDialog = false">
              <v-icon color="orange">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="expande-horizontal pa-3 wrap">
            <v-flex class="pa-1" xs12>
              <span class="fonte-italica fonte-subtitulo">
                Quantidade
              </span>
              <v-text-field
                solo
                flat
                autofocus
                dense
                class="fonte-subtitulo fonte-italica"
                background-color="#f2f2f2"
                placeholder="ex: 100"
                type="Number"
                hide-details
                v-model="get_estoque.quantidade"
              ></v-text-field>
            </v-flex>
            <v-flex class="pa-1" xs12>
              <span class="fonte-italica fonte-subtitulo">
                Unidade de Entrada
              </span>
              <v-select
                solo
                flat
                dense
                class="fonte-subtitulo fonte-italica"
                background-color="#f2f2f2"
                placeholder="ex: 100"
                type="Number"
                :items="['KG', 'GRAMA', 'ML', 'UNIDADE']"
                v-model="get_estoque.unidade_de_medida"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal">
                <v-btn
                  @click="adicionarEstoque"
                  color="green"
                  dark
                  class="fonte-subtitulo fonte-italica"
                  >Adicionar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </layout>
</template>

<script>
import Filtros from "../components/Filtros.vue";
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import Paginacao from "../components/Paginacao.vue";
import ListagemVazia from "../components/ListagemVazia.vue";
import ModalView from "../components/modalView.vue";
import { mapActions, mapGetters } from "vuex";
import ModalSendArchive from "../../shared/components/ModalSendArchive.vue";
import ModalSendArchiveById from "../../shared/components/ModalSendById.vue";
export default {
  data() {
    return {
      card: false,
      contextDialog: false,
      drawer: false,
      formEstoqueDialog: false,
      options: [
        {
          nome: "Novo insumo",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        },
        {
          nome: "Estoque",
          action: this.iniciaCadastroEstoque,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: ""
        },
        {
          name: "Meus insumos",
          path: "/insumos"
        }
      ],
      get_categoria: {
        insumos: []
      },
      nova_secao: "",
      formPrecoMultiplo: {}
    };
  },
  filters: {
    precoDeEntrada(val) {
      const quantidade = parseFloat(val.quantidade);
      const preco_de_compra = parseFloat(val.produto.preco_de_compra);
      const preco_da_grama = preco_de_compra / 1000;
      const total_de_entrada = quantidade * preco_da_grama;
      return total_de_entrada;
    }
  },
  components: {
    Filtros,
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    Paginacao,
    ListagemVazia,
    ModalView,
    ModalSendArchive,
    ModalSendArchiveById
  },
  computed: {
    ...mapGetters([
      "get_tenants",
      "get_estoque",
      "get_estoques",
      "get_tenants_filtros",
      "get_insumos_filtros",
      "get_estoques_filtros",
      "get_insumocategorias",
      "get_insumocategoria",
      "getLoggedUser",
      "get_insumo",
      "get_insumos",
      "getLinksById",
      "getPersonalizedLoading"
    ]),
    quantidadeEstoque() {
      const calcPreco = val => {
        const quantidade = parseFloat(val.quantidade);
        const preco_de_compra = parseFloat(val.produto.preco_de_compra);
        const preco_da_grama = preco_de_compra / 1000;
        const total_de_entrada = quantidade * preco_da_grama;
        return total_de_entrada;
      };
      let totalEstoque = {
        quantidade: 0,
        valor_total: 0
      };
      this.get_estoques.docs.map(estoque => {
        (totalEstoque.quantidade += estoque.quantidade),
          (totalEstoque.valor_total += calcPreco(estoque));
      });

      return totalEstoque;
    }
  },
  methods: {
    iniciaCadastroEstoque() {
      this.formEstoqueDialog = true;
      this.get_estoque.unidade_de_medida = this.get_insumo.unidade_de_medida;
    },
    abririnsumo(insumo) {
      this.$store.commit("set_insumo", insumo);
      this.$store.commit("setLinks", insumo.imagens);
      this.get_estoques_filtros.produto = insumo;
      this.listar_estoques();
    },
    removePrecoMultiplo(index) {
      let novoPrecoMultiplo = this.get_insumo.preco_multiplo.filter(
        (p, i) => i !== index
      );
      this.get_insumo.preco_multiplo = novoPrecoMultiplo;
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    closeContextDialog() {
      this.contextDialog = false;
      setTimeout(() => {
        this.drawer = true;
      }, 10);
    },
    poc(text) {
      console.log(text);
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_insumos",
      "criar_estoque",
      "listar_insumocategorias",
      "criar_insumo",
      "listar_estoques",
      "atualizar_insumo",
      "setPersonalizedLoading"
    ]),
    adicionarNovoPreco() {
      if (this.$refs.formNewPrecoMultiplo.validate()) {
        const preco = this.formPrecoMultiplo;
        this.get_insumo.preco_multiplo.push(preco);
        this.formPrecoMultiplo = {};
      }
    },
    criarOuAtualizar() {
      if (this.get_insumo._id) {
        this.atualizar_insumo();
      } else {
        this.criar_insumo();
      }
    },
    adicionarEstoque() {
      this.get_estoque.produto = this.get_insumo;
      this.get_estoque.origem = "insumo";
      this.criar_estoque();
      this.formEstoqueDialog = false;
    },
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_insumo", {
        new: true,
        preco_multiplo: []
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
      this.$store.commit("set_estoques", {
        docs: [],
        page: 1,
        totalPages: 1
      });
    },
    updateField(prod, campo, novo_valor) {
      let insumo = prod;
      insumo[campo] = novo_valor;
      this.$store.commit("set_insumo", insumo);
      this.atualizar_insumo();
    },
    addinsumo(categoria) {
      this.criar_insumo();
    }
  },
  created() {
    this.get_insumos_filtros.limitPerPage = 7;
    this.listar_insumos();
  }
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
